import React, {useState} from 'react';
import {Link, graphql} from 'gatsby';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import {useLocale} from '@app/hooks';

import {Layout, Seo} from '@app/components';

import {auth, db} from '../../firebase';
import {doc, setDoc, Timestamp} from 'firebase/firestore';
import {useDispatch, useSelector} from 'react-redux';

import {login} from '../redux/auth';
import '../styles/pages/login.scss';

const Login: React.FC = () => {
  const {user} = useSelector((state) => state.auth);
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const SCOPE_OPTIONS = {
    scope: 'pages.login',
  };
  const {t} = useLocale();
  const dispatch = useDispatch();

  const handleCreateNewUser = async (e) => {
    e.preventDefault();

    const user = await createUserWithEmailAndPassword(auth, mail, password)
      .then((result) => {
        const ref = doc(db, 'users', result.user.uid);
        const docRef = setDoc(ref, {
          mail,
          password,
          createAt: Timestamp.now(),
        });
        //setDoc(doc(db, 'posts', result.user.uid));

        console.log(result.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  //createUserWithEmailAndPassword(auth, 'mirayozcicek@gmail.com', '214365');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const {user} = await signInWithEmailAndPassword(auth, mail, password);

    if (user) {
      dispatch(login(user));

      redirectToAdminPage();
    }
  };

  const redirectToAdminPage = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/admin';
    }
  };

  if (user) {
    redirectToAdminPage();
  }

  return (
    <Layout>
      {!user && (
        <div className="layout layout--bg" style={{backgroundImage: 'none'}}>
          <div className="px-6 py-4 layout__inner">
            <form
              onSubmit={handleSubmit}
              className="w-80 m-auto text-center mt-5"
            >
              <div className="text-base font-bold">Login</div>

              <input
                className="login-input block text-lg w-full py-3 px-3 my-3"
                type="text"
                value={mail}
                placeholder="Mail"
                onChange={(e) => setMail(e.target.value)}
              />

              <input
                className="login-input block text-lg w-full py-3 px-3 my-3"
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <button type="submit" className="login-button my-5 py-3 px-3">
                Login
              </button>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Login;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
